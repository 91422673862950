import React from 'react'
import {
    Dialog, DialogContent, CircularProgress, makeStyles, Grid, IconButton, Typography, Button, TextField, Link
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close' 
import { popUpFormId, hubspotSubmitEndPoint, portalId } from '../../utitlities/Configurations'
import clsx from 'clsx'
import axios from 'axios'
import CheckIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import VideoComponent from '../General/VideoComponent'
import LinkComponent from '../Gatsby/LinkComponent'


const useStyles = makeStyles(theme => ({
    root: {
        // zIndex: `99999 !important`
    },
    paperProps: {
        borderRadius: '8px',
        maxWidth: "700px",
        width: "80%",
        backgroundColor: "#142840"
    },
    titlePadding: {
        padding: '1rem',
        [theme.breakpoints.up('sm')]: {
            padding: '0.9rem 1.56rem',
        }
    },
    contentPadding: {
        padding: '1.5rem !important',
        [theme.breakpoints.up('sm')]: {
            padding: '2rem !important',
        }
    },
    textColor: {
        color: "#5d6578",
        letterSpacing: '1px',
    },
    textSize: {
        fontSize: "0.875rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: '1rem'
        }
    },
    icon: {
        fontSize: "1.5rem",
        color: "#3a4151",
        [theme.breakpoints.up("sm")]: {
            fontSize: '3rem'
        }
    },
    button: {
        textTransform: "uppercase",
        textDecoration: 'underline',
        fontSize: '1rem',
        color: '#f39e2a',
        "&:hover": {
            textDecoration: 'underline',
            backgroundColor: 'transparent'
        }
    },
    buttonPrimary: {
        borderRadius: "4px",
        color: "#142840",
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        backgroundColor: "#f39e2a",
        height: '100%',
        fontWeight: 700,
        "&:hover": {
            backgroundColor: "#f39e2a"
        }
    },
    title: {
        fontSize: "2.5rem",
        letterSpacing: "1px",
        fontWeight: 'bold',
        [theme.breakpoints.up("sm")]: {
            fontSize: "5rem",
        }
    },
    input: {
        fontSize: '1rem',
        color: theme.palette.primary.main,
        padding: '18.5px'
    },
    successContainer: {
        height: '24px',
        [theme.breakpoints.up("sm")]: {
            height: '48px'
        }
    },

    SecondaryLink: {
        backgroundColor: '#ed2024',
        color: '#ffffff',
        borderRadius: '10px',     
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5em%',
        }
           
    },
    popUpImgSection: {
        float: 'left',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        [theme.breakpoints.up('md')]: {
            width: '35%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    popUpVideoSection: {
        float: 'left',
        [theme.breakpoints.up('sm')]: {
            width: '65%',
            marginLeft: '-5%',
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
            marginLeft: '-5%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        }
    },
    popUpLinkSection: {
        clear: 'both',
        margin: '0 auto',
        paddingTop: '50px',
        [theme.breakpoints.up('md')]: {
            width: '400px'
        }
    }
}))

function TrainingPopup({ open, onClose }) {
    const classes = useStyles()
    const [email, setEmail] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const closeHandle = () => {
        onClose()
    }

    React.useEffect(() => {
        if (open) {
            // let expiryDate = new Date(
            //     Date.now() + 1 * (1000 * 60 * 60 * 24)
            // )
            // document.cookie =
            //     "popup-seen=true; expires=" + expiryDate.toUTCString()
            window.sessionStorage.setItem('popup-seen', true)
        }
    }, [open])

    return (
        <Dialog open={open} onClose={closeHandle} scroll='body' classes={{
            root: classes.root
        }} PaperProps={{ className: classes.paperProps }}>
            <DialogContent className={classes.contentPadding}>
                <Grid container spacing={2} justifyContent='center'>
                    <Grid item container xs={12}>
                        <Grid item container justifyContent='flex-end' xs={12}>
                            <Grid item xs={12} style={{ textAlign: 'right' }}>
                                <IconButton style={{ padding: '0px' }} onClick={closeHandle}>
                                    <CloseIcon className={classes.icon} />
                                </IconButton>
                            </Grid>
                        </Grid>                        
                    </Grid>
                </Grid>
                <div>
                    <div className={classes.popUpImgSection}>
                        <img src='https://admin.navas.in/uploads/marketing-secrets/bookcover.png' width='100%'/>
                    </div>
                    <div className={classes.popUpVideoSection}>
                        <VideoComponent url='https://www.youtube.com/embed/Bb0Pe3oc-cg' title='video' />
                    </div>
                    <div className={classes.popUpLinkSection}>
                        <LinkComponent
                            showAsButton={true}
                            route="/marketing-secrets"
                            title="Unlock Free Marketing Secrets"
                            className={classes.SecondaryLink}
                            styles={{
                                fontSize: '2rem',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                fontWeight: 700,
                            }}
                        />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TrainingPopup